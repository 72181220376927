// Here you can add other styles

.small-spinner {
    --sk-size: 20px !important;
}

// detail row of datatable shouldn't have border but apparently it has
// remove it with below tag
tr {
	.p-0 {
		border: none !important;
	}
}

.border-start {
  border-left: 1px solid var(--cui-border-color, #d8dbe0) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-start-primary {
  border-left-color: #321fdb !important;
}

.border-start-secondary {
  border-left-color: #9da5b1 !important;
}

.border-start-success {
  border-left-color: #2eb85c !important;
}

.border-start-info {
  border-left-color: #39f !important;
}

.border-start-warning {
  border-left-color: #f9b115 !important;
}

.border-start-danger {
  border-left-color: #e55353 !important;
}

.border-start-light {
  border-left-color: #ebedef !important;
}

.border-start-dark {
  border-left-color: #4f5d73 !important;
}

.border-start-white {
  border-left-color: #fff !important;
}

.border-start-1 {
  border-left-width: 1px !important;
}

.border-start-2 {
  border-left-width: 2px !important;
}

.border-start-3 {
  border-left-width: 3px !important;
}

.border-start-4 {
  border-left-width: 4px !important;
}

.border-start-5 {
  border-left-width: 5px !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.progress {
  height: 4px;
}

.progress.progress-white {
  background-color: rgba(255, 255, 255, 0.2);
}
.progress.progress-white .progress-bar {
  background-color: #fff;
}

.progress-group {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 5px;
}

.progress-group-prepend {
  flex: 0 0 100px;
  align-self: center;
}

.progress-group-header {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: 0.25rem;
}

.progress-group-bars {
  flex-grow: 1;
  align-self: center;
}
.progress-group-bars .progress:not(:last-child) {
  margin-bottom: 2px;
}

.progress-group-header + .progress-group-bars {
  flex-basis: 100%;
}
